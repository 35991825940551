import React, { useState } from "react";
import Layout from "../components/layout";

import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { navigate } from "gatsby-link";
import SEO from "../components/seo";

import styles from "../styles/contact.module.scss";
import { FaClock, FaEnvelope, FaPhone } from "react-icons/fa";
import { TiLocation } from "react-icons/ti";

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Index = () => {
  const [fullName, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        fullName,
        tel,
        email,
        message,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <SEO title="Contact Us" article={false} pathname={`/contact`} />

      <Container fluid>
        <Row
          className={` justify-content-center align-items-center`}
          style={{ height: "100%" }}
        >
          <Col
            className={`${styles.leftCol} ${styles.wrapper} d-flex justify-content-center align-items-center p-5`}
          >
            <form
              className={`${styles.form} mt-5 mb-5`}
              name="contact"
              method="post"
              action="/success/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <label style={{ display: "none" }}>
                Don’t fill this out: <input name="bot-field" />
              </label>

              <h1 className={`${styles.title}  display-4 mb-3`}>
                Send Us A <span>Message</span>
              </h1>

              {/* Full Name */}
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Your Name"
                    name="fullName"
                    size="lg"
                    value={fullName}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>

              {/* Telephone */}
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Col>
                  <Form.Control
                    type="tel"
                    placeholder="Telephone Number"
                    name="tel"
                    size="lg"
                    value={tel}
                    onChange={(e) => {
                      setTel(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>

              {/* Full Name */}
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Col>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    size="lg"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>

              {/* Message*/}
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    placeholder="Message"
                    name="message"
                    rows="3"
                    size="lg"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>

              <Button
                variant="primary"
                size="lg"
                className="mt-4"
                style={{ width: "100%" }}
                type="submit"
                disabled={!fullName || !tel || !email || !message}
              >
                Submit
              </Button>
            </form>
          </Col>
          <Col
            sm={12}
            lg={6}
            className="lg-mt-0 p-5 justify-content-center align-items-center"
          >
            <div className="ms-lg-5 pt-5 pb-5">
              <h1 className={`${styles.title}  display-4 mb-4`}>
                Contact <span>Us</span>
              </h1>

              <h3 className={`${styles.title2} mb-5`}>How to reach us</h3>

              <div className={styles.item}>
                <div className="d-flex">
                  <TiLocation />
                  <h4>Head Office</h4>
                </div>
                <p className="small">
                  No. 349/A, Nugadeniya Road, Batapota, Battaramulla, Sri Lanka
                </p>
              </div>

              <div className={styles.item}>
                <div className="d-flex">
                  <FaPhone />
                  <h4>Hotline</h4>
                </div>
                <p className="small">+94-71-281-4071</p>
              </div>

              <div className={styles.item}>
                <div className="d-flex">
                  <FaEnvelope />
                  <h4>Email</h4>
                </div>
                <p className="small">info@blazen.lk</p>
              </div>

              <div className={styles.item}>
                <div className="d-flex">
                  <FaClock />
                  <h4>Operating Hours</h4>
                </div>
                <p className="small">Weekdays from 9:00AM - 5:00PM</p>
              </div>

              <div className={`text-lg-start ${styles.social}`}>
                <a
                  href="https://www.facebook.com/blazensupplements/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiFillFacebook />
                </a>
                <a
                  href="https://www.instagram.com/blazen_supplements/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiFillInstagram />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Index;
